import React from "react";
import { Box, CircularProgress, Typography, useTheme } from "@material-ui/core";
import useTranslate from "../../../../../../hooks/use-translate";

export default function VerifyingCode() {
  const theme = useTheme();
  const t = useTranslate();
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body2" color="textSecondary">
        {t("authentication.verifyingCode")}
      </Typography>
      <CircularProgress
        size={14}
        color="secondary"
        style={{ marginLeft: theme.spacing(1) }}
      />
    </Box>
  );
}
