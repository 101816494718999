import React, { useState } from "react";
import useTranslate from "../../../../hooks/use-translate";
import UserAccountLayout from "../../components/UserAccountLayout";
import { useAuthContextSelector } from "../../../../hooks/use-auth";
import PhoneNumberInputForm from "./PhoneNumberInputForm";
import {
  isPhoneNumberAlreadyRegistered,
  sendUserAuthenticationPhoneNumberOtpForUpdate,
  verifyUserAuthenticationPhoneNumberOtpForUpdate
} from "../../../../services/user";
import { prettifyErrorMessage } from "../../../../helpers/error";
import PhoneNumberOtpVerificationForm from "./PhoneNumberOtpVerificationForm";
import { navigate } from "gatsby";
import useLocalizedLocationPath from "../../../../hooks/use-localized-location-path";
import useCustomSnackbar from "../../../../hooks/use-custom-snackbar";

export default function ChangePhoneNumber() {
  const t = useTranslate();
  const getLocalizedPath = useLocalizedLocationPath();
  const snackbar = useCustomSnackbar();
  const updateLocalStateUser = useAuthContextSelector(
    c => c.updateLocalStateUser
  );
  const user = useAuthContextSelector(c => c.user);

  const [step, setStep] = useState<"phone-number" | "otp">("phone-number");
  const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState<string>(null);

  const handleSubmitPhoneNumber = async phoneNumber => {
    const isSameAsCurrentPhoneNumber =
      phoneNumber.replace(/\D/g, "") === user.phoneNumber;

    if (!isSameAsCurrentPhoneNumber) {
      const phoneAlreadyRegistered = await isPhoneNumberAlreadyRegistered(
        phoneNumber
      );
      if (phoneAlreadyRegistered) {
        throw new Error(
          t("accountSettings.changePhoneNumber.alreadyRegistered")
        );
      }
    }

    try {
      await sendUserAuthenticationPhoneNumberOtpForUpdate(phoneNumber);
      setVerifiedPhoneNumber(phoneNumber);
      setStep("otp");
    } catch (e) {
      snackbar.showError(prettifyErrorMessage(e?.message));
    }
  };

  const handleVerificationOtpSubmit = async otp => {
    try {
      const user = await verifyUserAuthenticationPhoneNumberOtpForUpdate(otp);
      updateLocalStateUser(user);
      snackbar.showSuccess(t("accountSettings.changePhoneNumber.success"));
      navigate(getLocalizedPath("/user/account"));
    } catch (e) {
      throw e;
    }
  };

  const title = t(
    user?.phoneNumber
      ? "accountSettings.changePhoneNumber"
      : "accountSettings.addPhoneNumber"
  );

  return (
    <UserAccountLayout
      backNavigation
      title={step === "phone-number" ? title : null}
      showLoading={!user}
    >
      {step === "phone-number" && (
        <PhoneNumberInputForm
          onSubmit={handleSubmitPhoneNumber}
          submitButtonText={t("submit")}
          label={t("phoneNumber")}
          defaultValue={user?.phoneNumber}
        />
      )}

      {step === "otp" && (
        <PhoneNumberOtpVerificationForm
          phoneNumber={verifiedPhoneNumber}
          onResendOtpCode={sendUserAuthenticationPhoneNumberOtpForUpdate}
          onVerificationOtpSubmit={handleVerificationOtpSubmit}
        />
      )}
    </UserAccountLayout>
  );
}
