/**
 * Prettify exception message returned by API request
 * @param error
 */
export function prettifyErrorMessage(error: string) {
  if (!error || error.toLowerCase() === "no message available") {
    return "Something went wrong. Please contact customer support for assistance.";
  }

  if (error.toLowerCase() === "failed to fetch") {
    return "Network is unreachable. Please make sure you are connected to the internet.";
  }

  if (
    error.toLowerCase() === "otp expired/invalid" ||
    error.toLowerCase() === "token expired/invalid" ||
    error.toLowerCase() === "invalid verification code entered" ||
    error.toLowerCase() === "wrong input"
  ) {
    return "The code you provided is expired or invalid. Try sending a new code.";
  }

  return error;
}
