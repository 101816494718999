import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default function SmsIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      style={{ fill: "none", ...(props.style || {}) }}
    >
      <path
        d="M11.9952 4.32227C7.22931 4.32227 3.36133 7.41319 3.36133 11.2294C3.38529 12.1949 3.63142 13.1419 4.08062 13.9969C4.52983 14.8518 5.17001 15.5918 5.95149 16.1593C6.03833 16.4672 6.06228 16.7894 6.02192 17.1068C5.98156 17.4241 5.87771 17.7301 5.71657 18.0065C5.55543 18.2828 5.34029 18.5239 5.08398 18.7154C4.82767 18.9068 4.44841 19.0613 4.22472 19.1207C4.00103 19.1801 3.94843 19.4747 4.63051 19.6042C7.38472 20.105 10.0654 17.8775 10.0654 17.8775C10.8472 18.0557 11.202 18.1023 12.0038 18.1019C16.7697 18.1019 20.6377 15.011 20.6377 11.1948C20.6377 7.37866 16.7611 4.32227 11.9952 4.32227Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
