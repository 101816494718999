import { Box, Button, CircularProgress, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import SmsIcon from "../../../../components/icons/sms";
import PhoneNumberInput, {
  validatePhoneNumber
} from "../../../../components/phone-number-input";
import { prettifyErrorMessage } from "../../../../helpers/error";
import useCustomSnackbar from "../../../../hooks/use-custom-snackbar";
import useTranslate from "../../../../hooks/use-translate";

type Props = {
  onSubmit: (email: string) => void;
  onChangeMode?: () => void;
  label?: string;
  defaultValue?: string;
  submitButtonText: string;
};

export type AuthenticateMethodFormDataType = {
  phoneNumber?: string;
};

export default function PhoneNumberInputForm({
  onSubmit,
  submitButtonText,
  label,
  defaultValue
}: Props) {
  const t = useTranslate();
  const snackbar = useCustomSnackbar();

  const [isSubmiting, setSubmiting] = useState(false);
  const [error, setError] = useState<Error>();

  const { register, handleSubmit, errors } =
    useForm<AuthenticateMethodFormDataType>({
      defaultValues: {
        phoneNumber: defaultValue
      }
    });

  const handleFormSubmit = async (data: AuthenticateMethodFormDataType) => {
    const { phoneNumber } = data;
    setSubmiting(true);
    try {
      await onSubmit(phoneNumber);
      setSubmiting(false);
    } catch (e) {
      setSubmiting(false);
      snackbar.showError(prettifyErrorMessage(e?.message));
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Box mb={2}>
        <TextField
          id="phoneNumber"
          name="phoneNumber"
          variant="outlined"
          label={label || t("phoneNumber")}
          placeholder="(000) 000-0000"
          fullWidth
          inputRef={register({
            required: t("error.required"),
            validate: validatePhoneNumber
          })}
          InputProps={{
            inputComponent: PhoneNumberInput,
            startAdornment: <SmsIcon />,
            onChange: () => {
              if (error) setError(null);
            }
          }}
          error={!!errors.phoneNumber}
          helperText={errors.phoneNumber?.message}
          disabled={isSubmiting}
          autoComplete="tel"
        />

        {!error && (
          <Box mt={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={isSubmiting || !!error}
            >
              {!isSubmiting && submitButtonText}
              {isSubmiting && <CircularProgress size={16} color="secondary" />}
            </Button>
          </Box>
        )}

        {error && (
          <Box mt={2}>
            <Alert severity="error">{error.message}</Alert>
          </Box>
        )}
      </Box>
    </form>
  );
}
