import React from "react";
import { graphql } from "gatsby";
import Page from "../../../components/page";
import ProtectedRoute from "../../../containers/user/components/ProtectedRoute";
import ChangePhoneNumber from "../../../containers/user/containers/ChangePhoneNumber";

export const AccountSettingsChangePhoneNumberPage = ({
  data
}: {
  data: Queries.ChangePhoneNumberQueryDataQuery;
}) => {
  return (
    <Page {...data.datoCmsWebPage} container="fluid">
      <ProtectedRoute component={ChangePhoneNumber} />
    </Page>
  );
};

export const query = graphql`
  query ChangePhoneNumberQueryData($locale: String!) {
    datoCmsWebPage(
      path: { eq: "user/account/phone-number" }
      locale: { eq: $locale }
    ) {
      ...WebPageFragment
    }
  }
`;

export default AccountSettingsChangePhoneNumberPage;
