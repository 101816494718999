import { Box, Button, Typography, useTheme } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useRef, useState } from "react";
import SmsIcon from "../../../../components/icons/sms";
import { prettifyErrorMessage } from "../../../../helpers/error";
import { prettifyPhoneNumber } from "../../../../helpers/phone-number";
import useCustomSnackbar from "../../../../hooks/use-custom-snackbar";
import useTranslate from "../../../../hooks/use-translate";
import CustomReactCodeInput, {
  clearReactCodeInput
} from "../../../authentication/containers/Authenticate/Form/components/CustomReactCodeInput";
import VerifyingCode from "../../../authentication/containers/Authenticate/Form/components/VerifyingCode";

const VERIFICATION_CODE_COUNT = 6;

type Props = {
  phoneNumber: string;
  onResendOtpCode: (phoneNumber: string) => Promise<any>;
  onVerificationOtpSubmit: (otp: string) => Promise<void>;
};

export default function PhoneNumberOtpVerificationForm({
  phoneNumber,
  onResendOtpCode,
  onVerificationOtpSubmit
}: Props) {
  const theme = useTheme();
  const t = useTranslate();
  const snackbar = useCustomSnackbar();

  // Verification code
  const [error, setError] = useState<string>(null);
  const [loading, setLoading] = useState(false);
  const [isValidVerificationCode, setValidVerificationCode] = useState(false);

  const handleCodeChange = (value: string) => {
    if (error) {
      setError(null);
    }
    if (value.length >= VERIFICATION_CODE_COUNT) {
      submitCode(value);
    }
  };

  // When user enters all keys
  const submitCode = async (verificationCode: string) => {
    setLoading(true);
    try {
      await onVerificationOtpSubmit(verificationCode);
      setValidVerificationCode(true);
    } catch (e) {
      setLoading(false);
      if (e?.message) {
        setError(e?.message);
      } else {
        snackbar.showError(t("error.cantConnectToServer"));
      }
      return;
    }
  };

  // Resend code
  const [isResending, setResending] = useState(false);
  const handleResendClick = async () => {
    setResending(true);
    try {
      await onResendOtpCode(phoneNumber);
      snackbar.showMessage(t("authentication.anotherPhoneCodeTexted"));
      setResending(false);
    } catch (e) {
      setResending(false);
      snackbar.showError(e?.message || t("error.cantConnectToServer"));
    }
  };

  // Clear code
  const inputCodeRef = useRef(null);
  const handleClearCode = () => {
    setError(null);
    clearReactCodeInput(inputCodeRef.current);
  };

  return (
    <>
      <Box mb={{ xs: 4, sm: 8 }}>
        <Typography variant="h5">{t("authentication.textedCode")}</Typography>
      </Box>

      <Box>
        <Box
          bgcolor="grey.300"
          textAlign="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          borderRadius="3px"
          padding={1}
          pb={2}
        >
          <Box mt={-3}>
            <SmsIcon
              fontSize="large"
              style={{ fill: theme.palette.background.default }}
            />
            <Typography>
              {t("authentication.codeSentTo", {
                to: prettifyPhoneNumber(phoneNumber) || phoneNumber
              })}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="center" mt={2}>
            <CustomReactCodeInput
              fields={VERIFICATION_CODE_COUNT}
              type="text"
              name="code"
              inputMode="tel"
              onChange={handleCodeChange}
              inputStyle={{}}
              inputStyleInvalid={{}}
              isValid={!error}
              isValidPodKey={isValidVerificationCode}
              disabled={loading}
              filterKeyCodes={[]}
              filterChars={[]}
              // @ts-ignore
              ref={inputCodeRef}
            />
          </Box>
          {loading && <VerifyingCode />}
        </Box>

        {error && (
          <Box mt={2}>
            <Alert severity="error">{prettifyErrorMessage(error)}</Alert>

            <Box mt={2}>
              <Button variant="contained" fullWidth onClick={handleClearCode}>
                Clear code
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      <Box mt={4}>
        <Button
          variant="contained"
          fullWidth
          onClick={handleResendClick}
          disabled={isResending}
        >
          {isResending && t("authentication.resending")}
          {!isResending && t("authentication.sendItAgain")}
        </Button>
      </Box>
    </>
  );
}
