import { styled, Theme } from "@material-ui/core";
import ReactCodeInput from "react-code-input";

const CustomReactCodeInput = styled(ReactCodeInput)(
  ({ theme, isValidPodKey }: { theme: Theme; isValidPodKey?: boolean }) => ({
    "& > input": {
      textAlign: "center",
      fontWeight: 600,
      fontSize: "2.4rem",
      border: isValidPodKey
        ? `2px solid ${theme.palette.info.main}`
        : `1px solid ${theme.palette.grey[300]}`,
      outline: 0,
      width: "40px",
      height: "52px",
      padding: "0px",
      marginRight: "8px",
      marginBottom: "8px",
      borderRadius: "4px",
      fontFamily: "'Roboto Mono', monospace",
      boxShadow: "none",
      overflow: "visible",
      "&:focus": {
        border: !isValidPodKey ? `2px solid ${theme.palette.grey[600]}` : null
      },
      "&:disabled": {
        backgroundColor: `${theme.palette.grey[50]} !important`
      },
      '&[data-valid="false"]': {
        border: `2px solid ${theme.palette.error.main}`
      }
    }
  })
);

/**
 * Dirty way to clear react-code-input values and refocus on first input
 * Reference: https://github.com/40818419/react-code-input/issues/69
 */
export function clearReactCodeInput(reactCodeInputRef: any) {
  if (
    reactCodeInputRef &&
    reactCodeInputRef.textInput &&
    reactCodeInputRef.textInput.length > 0
  ) {
    for (let i = 0; i < reactCodeInputRef.textInput.length; i++) {
      reactCodeInputRef.state.input[i] = "";
      (reactCodeInputRef.textInput[i] as HTMLInputElement).value = "";
    }
    reactCodeInputRef.textInput[0]?.focus();
  }
}

export default CustomReactCodeInput;
