import React from "react";
import MaskedInput from "react-text-mask";

interface Props {
  inputRef: (ref: HTMLInputElement | null) => void;
}

export default function PhoneNumberInput(props: Props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      // prettier-ignore
      mask={['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      type="tel"
    />
  );
}

// same rule as mask above
export function validatePhoneNumber(value) {
  if (value && /\([0-9]{3}\) [0-9]{3}-[0-9]{4}/.test(value)) {
    return true;
  } else {
    return "Please enter a valid phone number.";
  }
}
