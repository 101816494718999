import { useSnackbar } from "material-ui-snackbar-provider";
import { CustomSnackbarType } from "../components/custom-snackbar";

/**
 * Helper to show error/success/info snackbar with simple parameters
 */
export default function useCustomSnackbar() {
  const snackbar = useSnackbar();

  const showMessage = (message: string, variant?: CustomSnackbarType) =>
    snackbar.showMessage(message, null, null, {
      //@ts-ignore
      variant: variant || "info"
    });

  return {
    showMessage,
    showError: (message: string) => showMessage(message, "error"),
    showSuccess: (message: string) => showMessage(message, "success")
  };
}
